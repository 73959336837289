export type TagType = 'detection_type' | 'light_source' | 'shape' | 'distance'
export const tagType: Record<string, string> = {
  detection_type: '检测方式',
  light_source: '光源',
  shape: '外形',
  distance: '距离'
}

export const tags: Record<string, Record<string, string>> = {
  detection_type: {
    // All: '全部',
    Through_Ray_Detection: '对射检测',
    Diffuse_Detection: '漫反射',
    Background_Suppression: '背景抑制',
    Polarized_Mirror_Reflection: '偏振镜反射',
  },
  light_source: {
    // All: '全部',
    Infrared_Light: '红外光',
    RedLight: '红光',
    BlueLight: '蓝光',
    WhiteLight: '白光',
    GreenLight: '绿光',
    VCSEL_Laser: 'VCSEL激光光源',
    Laser: '激光',
  },
  shape: {
    // All: '全部',
    Square: '方形',
    Cylindrical: '圆柱形',
    Groove: '槽型',
  },
  distance: {
    // All: '全部',
    Ten_Meter: '10米以上',
    Three_To_Ten_Meter: '3米 - 10米',
    One_To_Three_Meter: '1米 - 3米',
    Half_To_One_Meter: '500mm - 1米',
    Less_Than_Half_Meter: '500mm以内',
  }
}
