import React, {useEffect, useState, useRef} from 'react';
import {PageProps} from 'gatsby';
import queryString from 'query-string';
import DefaultLayout from '../layouts/default';
import { HomeOutlined, UnorderedListOutlined, InfoCircleFilled } from '@ant-design/icons'
import {Breadcrumb, Collapse, Typography, Spin, Empty, message} from 'antd';
import * as styles from './products.module.scss';
import HfSeo from '../components/hf-seo';
import categoryData from '../const/category-data';
import TagFilter from '../components/tag-filter';
import ProductItem from '../components/product-item';
import axios from 'axios';
import { intersection } from 'lodash';
import mobile from 'is-mobile';

const { Panel } = Collapse;
const { Title } = Typography;

const ProductsPage = ({
  location,
  data,
}: PageProps<{directus: {product: Product[]}}>) => {
  // const categoryConfig = useRef(categoryData);
  const parsed = queryString.parse(location.search);
  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [activeCategory, setActiveCategory] = useState<null | number>();
  const [activeSubCategory, setActiveSubCategory] = useState<null | number>();
  const [completeProductList, setCompleteProductList] = useState<any[]>([])
  const [activeProductList, setActiveProductList] = useState<any[]>([])

  const handleFilterChange = (filterSelect: Record<string, string[]>) => {
    // console.log(filterSelect)
    filterProduct(filterSelect)
  }

  const handleSubCategoryClick = async (id: number) => {
    const parentCategoryId = String(id).slice(0, 1) // 前一位是一级类目ID
    const keyword = parsed.keyword as string || '';
    // setActiveCategory(Number(parentCategoryId))
    // setActiveSubCategory(id)

    const url = `/products?id=${Number(parentCategoryId)}&sid=${id}${keyword ? `&keyword=${keyword}` : ''}`
    console.log(url);

    window.location.href = url;

    // setSpinning(true);
    // const { data } = await axios.get(
    //   `http://wap.heyfocustech.com/api/products`,
    //   {
    //     params: {
    //       sub_category: id,
    //       keyword
    //     }
    //   }
    // )
    // setCompleteProductList(data.data)
    // setActiveProductList(data.data)
    // setSpinning(false);
  }

  const filterProduct = (filter: Record<string, string[]>) => {
    const newList: Product[] = [];
    completeProductList.forEach(p => {
      const tagTypes = Object.keys(filter) as Array<string>;
      let result = true;
      tagTypes.forEach(type => {
        const oneTypeFilter = JSON.parse(JSON.stringify(filter[type])) // 当前筛选器单一类型的选项
        const currentTypeTags = JSON.parse(JSON.stringify(p[type] || []));
        if (oneTypeFilter.length > 0 && !intersection(oneTypeFilter, currentTypeTags).length) {
          result = false
        }
      })
      if (result) {
        newList.push(p)
      }
    })
    setActiveProductList(newList)
  }

  const updateProductListWithKeyword = async (queryId: string, subCategoryId: string, keyword: string) => {
    const activeCategory = Number(queryId) || categoryData[0].id;

    // 默认展示该一级类目下第一个二级类目
    const defaultActiveSubCategory = Number(subCategoryId) || (
      categoryData[activeCategory - 1].children ?
      categoryData[activeCategory - 1].children[0].id : null
    );
    setActiveCategory(activeCategory)
    setActiveSubCategory(defaultActiveSubCategory)

    const initProduct = async () => {
      const { data } = await axios.get(
        `http://wap.heyfocustech.com/api/products`,
        {
          params: {
            sub_category: activeSubCategory || defaultActiveSubCategory,
            keyword
          }
        }
      )

      setCompleteProductList(data.data)
      setActiveProductList(data.data)
    }

    initProduct();
  }

  useEffect(() => {
    const queryId = parsed.id as string || '0';
    const subCategoryId = parsed.sid as string || '0';
    const keyword = parsed.keyword as string || '';

    const warning = () => {
      message.warning('检测到您正在使用移动设备访问官网，3秒后跳转至手机端...');
    };

    if (mobile()) {
      warning();
      const wapUrl = `http://wap.heyfocustech.com/#/category/${Number(queryId) - 1}`
      console.log(wapUrl)
      setTimeout(() => {
        window.location.href = wapUrl
      }, 3000)
    }

    updateProductListWithKeyword(queryId, subCategoryId, keyword)
  }, [])

  return (
    <DefaultLayout location={location}>
      <Spin spinning={spinning}>
      <div className={styles.container}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/"><HomeOutlined style={{ marginRight: 8, marginBottom: 36 }} />首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/category">产品中心</a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.mainContainer}>
          <div className={styles.categoryFilter}>
            <Title level={4}><UnorderedListOutlined style={{ marginRight: 8 }} />产品中心</Title>
            <Collapse defaultActiveKey={categoryData.map(c => c.id)}>
              {categoryData.map(c => {
                return (<Panel header={c.text} key={c.id}>
                  { c.children && c.children.map(subCategory => {
                    return (
                      <div
                        className={`${ subCategory.id == activeSubCategory ? styles.filterItemsActive : styles.filterItems }`}
                        key={subCategory.id}
                        onClick={() => handleSubCategoryClick(subCategory.id)}
                      >
                        {subCategory.text}
                      </div>)
                  }) }
                </Panel>)
              })}
            </Collapse>
          </div>
          <div className={styles.products}>
            <Title level={3}>
              <InfoCircleFilled style={{ marginRight: 8, color: '#e20212' }} />
              { activeCategory && categoryData.filter(c => c.id === activeCategory)[0].text }
            </Title>
            {
              activeSubCategory && (
                <div className={styles.tagFilter}>
                  <TagFilter
                    activeSubCategory={activeSubCategory}
                    handleFilterChange={handleFilterChange}
                  />
                </div>
              )
            }
            <div className={styles.productList}>
              { activeProductList.length ?
                activeProductList.map(
                  productData => <ProductItem key={productData.id} productData={productData} />) :
                  <Empty style={{ marginTop: 32 }} description='暂无相关产品' /> }
            </div>
          </div>
        </div>
      </div>
      </Spin>
    </DefaultLayout>
  );
};

export default ProductsPage;

export const Head = () => <HfSeo title="产品中心 | 唯视智能" />;
