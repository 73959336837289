import axios from 'axios';
import React, { useEffect, useState } from 'react';
import * as styles from './index.module.scss';
import { tagType, tags, type TagType } from '../../const/tags';

type PropTypes = {
  activeSubCategory: number;
  handleFilterChange: (filterSelect: Record<string, string[]>) => void
};

export default function TagFilter(props: PropTypes) {
  const [ tagTypeEnum, setTagTypeEnum ] = useState(tagType)
  const [ tagsEnum, setTagsEnum ] = useState(tags)
  const [ hasFilter, setHasFilter ] = useState(false);
  const { handleFilterChange, activeSubCategory } = props
  const [ filterSelect, setFilterSelect ] = useState<any>({});

  const resetFilter = (tagType: Record<string, string>) => {
    const initFilterSelect: any = {}
    Object.keys(tagType).forEach(t => {
      initFilterSelect[t] = []
    })
    setFilterSelect(initFilterSelect)
  }

  const initFilter = async () => {
    const { data } = await axios.get(`http://wap.heyfocustech.com/api/fields?sub_category=${activeSubCategory}`)
    if (data.hasFilter) {
      setTagTypeEnum(data.tagType)
      setTagsEnum(data.tags)
      resetFilter(data.tagType);
    }
    setHasFilter(!!data.hasFilter);
  }


  const handleFilterItemClick = (tagTypeId: string, tagName: string) => {
    // console.log(filterSelect, tagTypeId, tagName)
    const currentTagIsActive = filterSelect[tagTypeId].includes(tagName);
    const newFilterSelect = {
      ...filterSelect,
      [tagTypeId]: currentTagIsActive
        ? filterSelect[tagTypeId].filter(t => t !== tagName)
        : [...filterSelect[tagTypeId], tagName]
    }
    setFilterSelect(newFilterSelect)
    handleFilterChange(newFilterSelect)
  }

  useEffect(() => {
    setHasFilter(false)
    initFilter();
  }, [activeSubCategory]);

  return (
    hasFilter && (<div className={styles.filterContainer}>
      {
         Object.keys(tagTypeEnum).map(typeId => {
          return (
            <div key={typeId} className={styles.tagsContainer}>
              <div key={typeId} className={styles.filterTitle}>{tagTypeEnum[typeId]}：</div>
              {/* { console.log(tagsEnum, typeId) } */}
              { Object.keys(tagsEnum[typeId]).map(tagItemId => {
                return (
                  <div
                    key={tagItemId}
                    className={`${ filterSelect[typeId] && filterSelect[typeId].includes(tagsEnum[typeId][tagItemId]) ? styles.filterItemsActive : styles.filterItems }`}
                    onClick={() => handleFilterItemClick(typeId, tagsEnum[typeId][tagItemId])}
                  >
                    {tagsEnum[typeId][tagItemId]}
                  </div>
                  )
              }) }
            </div>
          )
        })
      }
    </div>
  ));
}
