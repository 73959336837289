import React, { useEffect, useState } from 'react';
import * as styles from './index.module.scss';
import { FilePdfOutlined } from '@ant-design/icons';

type PropTypes = {
  productData: any
};

export default function ProductItem(props: PropTypes) {
  const { productData } = props

  return (
    <div className={styles.productItems}>
      <div
        className={styles.cover}
        style={{ backgroundImage: `url(${productData.image})` }}
      ></div>
      <div className={styles.productInfo}>
        <div className={styles.productInfoTitle}>{productData.product_name}</div>
        <div className={styles.productInfoDescription}>{productData.description}</div>
        <div className={styles.productInfoTags}>
          { productData.tags.map((tag: string) => <div key={tag} className={styles.tag}>{tag}</div>) }
        </div>
      </div>
      <div
        onClick={() => window.open(productData.file, '_blank', 'noopener')}
        className={styles.productUrl}
      >
        <FilePdfOutlined style={{ fontSize: 30 }} />
        下载资料
      </div>
    </div>
  )
}
